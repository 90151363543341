import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ModalProvider } from 'styled-react-modal';
import reportWebVitals from 'reportWebVitals';
import dayjs from 'dayjs';
import { HelmetProvider } from 'react-helmet-async';

import 'sanitize.css/assets.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/sanitize.css';

import { App } from 'app';

import { configureAppStore } from 'store/configureStore';
import { ThemeProvider } from 'styles/theme/ThemeProvider';
import { AuthProvider } from './context/AuthContext';
import { ModalBackground } from './app/components/Modal';

import './locales/i18n';
import { PrintProvider } from './context/PrintContext';
import { HistoryProvider } from './context/HistoryContext';

const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

ReactDOMClient.createRoot(MOUNT_NODE!).render(
  <Provider store={store}>
    <ThemeProvider>
      <PrintProvider>
        <HelmetProvider>
          <BrowserRouter>
            <HistoryProvider>
              <ModalProvider backgroundComponent={ModalBackground}>
                <AuthProvider>
                  <App />
                </AuthProvider>
              </ModalProvider>
            </HistoryProvider>
          </BrowserRouter>
        </HelmetProvider>
      </PrintProvider>
    </ThemeProvider>
  </Provider>,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {});
}

reportWebVitals();

Date.prototype.toJSON = function () {
  return dayjs(this).format();
};
